import React from "react";

import "../../utilities.css";
import "./EventStatus.css";

import { statusToColor } from "../../data/colorKeys.js";

/**
 * Eventstatus is a component that displays the status of individual WCR events.
 *
 * Proptypes
 * @param {string} startDate
 * @param {string} endDate
 * @param {string} status
 */

const EventStatus = ({startDate, endDate, status}) => {
  const currentTime = new Date()
  let eventStatus;

  if (status === "Confirmed") {
    if (endDate) {
      if (currentTime < new Date(endDate) && new Date(startDate) <= currentTime) {
        eventStatus = "Happening Now"
      } else {
        eventStatus = "Confirmed"
      }
    } else {
      if (new Date(currentTime.getTime() - 60*60000) < new Date(startDate) && new Date(startDate) <= currentTime) {
        eventStatus = "Happening Now"
      } else {
        eventStatus = "Confirmed"
      }
    }
  } else {
    eventStatus = status || "To be confirmed"
  }

  return (
    <>
      { eventStatus !== "Confirmed" && <div style={{
        padding: ".25rem",
        textAlign: "center",
        marginBottom: ".5rem",
        backgroundColor: `rgb(${statusToColor[eventStatus].bkgColor})`,
        color: `rgb(${statusToColor[eventStatus].textColor})`
      }}>
        {eventStatus}
      </div>}
    </>
  )
}

export default EventStatus;