import React, {useState, useEffect} from "react";
import qs from "qs";
// import { Fade } from "react-awesome-reveal";

import axios from "axios";
import ContentBlock from "../../modules/ContentBlock.js";
import ButtonFlex from "../../modules/ButtonFlex.js";
import Image from "../../modules/Image.js";
import TopBanner from "../../modules/TopBanner.js";
import FilterMenu from "../../modules/FilterMenu.js";
import AllEvents from "../../modules/AllEvents.js";

import { tagToColor, catToColor } from "../../../data/colorKeys.js"

import TopBannerImage from "../../../img/topbanner/temp_univ_goose.jpg";
import default_img_src from "../../../img/logo/univ_192x192.png";

import "../../../utilities.css"
import "./Freshers.css";

const defaultNatureFilters = {
  "Academic Related": false,
  "Arts": false,
  "Chaplaincy": false,
  "College Development": false,
  "Social": false,
  "Sports": false,
  "Welfare": false,
  "Equality and Access": false,
  "LGBTQ+": false,
  "Other": false,
  // we want to filter freshers by default so not include the Freshers filter.
  // "Freshers": true, 
}

const defaultCatFilters = {
  "College Official": true,
  "Common Room": true,
  "Student Society": false,
  "Other Initiatives": false,
}


const Freshers = () => {
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);

  const getNews = async () => {
    const BaseURL = "https://wcr.univ.ox.ac.uk/strapi/api"
    const query = qs.stringify({
      sort: ['updatedAt:desc'],
      pagination: {limit: 25},
      populate: {
        banner: {
          fields: ['url', 'alternativeText']
        },
      },
    })
    const response = await axios.get(`${BaseURL}/news?${query}`);
    setNews(response.data.data);
  };

  useEffect(() => {getNews();}, []);

  const getEvents = async () => {
    const BaseURL = "https://wcr.univ.ox.ac.uk/strapi/api"
    const query = qs.stringify({
      sort: ['startDate'],
      pagination: {limit: 200},
      populate: {
        banner: {
          fields: ['url', 'alternativeText']
        },
        natures: {
          fields: ['name']
        }
      },
    })

    const response = await axios.get(`${BaseURL}/events?${query}`);
    const allEvent = response.data.data;

    // filter for all freshers event
    const freshersEvent = allEvent.filter(
      (item) => {
        return item.attributes.natures.data.some(
          (oneNature) => {return oneNature.attributes.name === "Freshers"}
        )
      }
    )

    setEvents(freshersEvent);
  };

  useEffect(() => {getEvents();}, []);

  const [natureFltr, setNatureFltr] = useState(defaultNatureFilters);
  const [catFltr, setCatFltr] = useState(defaultCatFilters);

  const updateNatureFilter = (f) => {
    let newNatureFltr = { ...natureFltr }
    newNatureFltr[f] = !newNatureFltr[f]
    setNatureFltr(newNatureFltr)
  }

  const updateCatFilter = (f) => {
    let newCatFltr = { ...catFltr }
    newCatFltr[f] = !newCatFltr[f]
    setCatFltr(newCatFltr)
  }

  return (
    <>
      <TopBanner
        backgroundImage={TopBannerImage}
        bgColorKey="black"
        title="Freshers" 
        content="Freshers' Information" />
      <ContentBlock title="Hey there!">
        <p>You should have received official welcomes from various College members, and we look forward to your arrival as much as they do! 
          Please do ensure that you have completed the registrations on the College's <a href="https://www.univ.ox.ac.uk/learn-at-univ/new-students">New Students</a> page.
          You can find on this page the WCR welcoming letters, as well as other freshers' week information.</p>
        <ButtonFlex display={[
          {key: 1, isLocal: false, des: "Official New Student Page", link: "https://www.univ.ox.ac.uk/learn-at-univ/new-students"},
        ]}/>
        <div className="u-gridPic-3">
          {news.map(
              (item) => {
                return (
                  <Image 
                    key={item.id}
                    title="" 
                    src={item.attributes.banner.data ? item.attributes.banner.data.attributes.url : default_img_src}
                    opacity={item.attributes.banner.data ? 1 : .5}
                    isBlack={Boolean(true)}
                    aspect="16/9"
                    suppressArrow={Boolean(true)}
                    des={<>
                      <h4 className="u-nomargin">{item.attributes.title}</h4>
                      <p>{item.attributes.content.slice(0, 85)}...</p>
                    </>}
                    linkdes={`/welcome/${item.attributes.shortTitle}`}
                    isLocal={Boolean(true)}
                    altText={item.attributes.banner.data ? item.attributes.banner.data.attributes.alternativeText : ""}/>
                )}
            )}
        </div>
      </ContentBlock>
      <ContentBlock title="Freshers' Events">
        <p>We have just dropped the Freshers' timetables for 2024, and they are now loaded on this page. 
          Click on the event thumbnail (image) to view further event details. 😊
          You can also add the events into your Google or Microsoft 365 (Outlook) calendars, provided you have logged into any of those accounts. 
          However, please do check this page regularly for any event updates!</p>
        <p>
          You can download a PDF version of the timetables below.
          Updates on the Freshers' timetables will be announced on this website and circulated via our usual channels: email, Facebook and/or WhatsApp.
        </p>
        <ButtonFlex display={[
          {key: "prewelcome", isLocal: false, des: "Pre-Welcome Week", link: "https://www.univ.ox.ac.uk/wp-content/uploads/2024/08/Pre-Welcome-Week-2024_FINAL.pdf"},
          {key: "welcome", isLocal: false, des: "Welcome Week", link: "https://www.univ.ox.ac.uk/wp-content/uploads/2024/09/Graduate-Welcome-Week-timetable-2024_FINAL-1.pdf"},
        ]}/>
        <section className="u-gridPic-2 freshers-filters">
          <div>
            <h3>Filter by the nature of events:</h3>
            <FilterMenu 
              filter={natureFltr}
              updateFilter={updateNatureFilter}
              colorKeys={tagToColor}
            />
          </div>
          <div>
            <h3>Filter by organiser:</h3>
            <FilterMenu 
              filter={catFltr}
              updateFilter={updateCatFilter}
              colorKeys={catToColor}
            />
          </div>
        </section>
        <AllEvents 
          eventsArr={events}
          natureFltr={natureFltr}
          catFltr={catFltr}
        />
      </ContentBlock>
    </>
  );
};
  
export default Freshers;
