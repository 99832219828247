import React, {useState, useEffect} from "react";
import qs from "qs";
// import { Fade } from "react-awesome-reveal";

import axios from "axios";
import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import FilterMenu from "../../modules/FilterMenu.js";
import AllEvents from "../../modules/AllEvents.js";

import { tagToColor, catToColor } from "../../../data/colorKeys.js"

import food_in_wcr from "../../../img/topbanner/food_in_wcr.png";

import "../../../utilities.css"
import "./WhatsOn.css";

const defaultNatureFilters = {
  "Academic Related": false,
  "Arts": false,
  "Chaplaincy": false,
  "College Development": false,
  "Equality and Access": false,
  "LGBTQ+": false,
  "Social": false,
  "Sports": false,
  "Welfare": false,
  "Other": false,
  "Freshers": false,
}

const defaultCatFilters = {
  "College Official": false,
  "Common Room": false,
  "Student Society": false,
  "Other Initiatives": false,
}

const WhatsOn = () => {
  const [events, setEvents] = useState([]);

  const getEvents = async () => {
    const BaseURL = "https://wcr.univ.ox.ac.uk/strapi/api";
    const query = qs.stringify({
      sort: ['startDate'],
      pagination: {limit: 200},
      populate: {
        banner: {
          fields: ['url', 'alternativeText']
        },
        natures: {
          fields: ['name']
        }
      },
    });

    const response = await axios.get(`${BaseURL}/events?${query}`);
    setEvents(response.data.data);
  };

  useEffect(() => {getEvents();}, []);

  const [natureFltr, setNatureFltr] = useState(defaultNatureFilters);
  const [catFltr, setCatFltr] = useState(defaultCatFilters);

  const updateNatureFilter = (f) => {
    let newNatureFltr = { ...natureFltr }
    newNatureFltr[f] = !newNatureFltr[f]
    setNatureFltr(newNatureFltr)
  }

  const updateCatFilter = (f) => {
    let newCatFltr = { ...catFltr }
    newCatFltr[f] = !newCatFltr[f]
    setCatFltr(newCatFltr)
  }

  return (
    <>
      <TopBanner
        backgroundImage={food_in_wcr}
        bgColorKey="black"
        title="What's On" 
        content="List of Events" />
        
      <ContentBlock title="Events">
        <p>Click on the event thumbnail (image) to view further event details. 😊
          You can now add the events into your Google or Microsoft 365 (Outlook) calendars, provided you have logged into any of those accounts. 
          However, please do check this page regularly for any event updates!</p>
        <p>You can use the filters below to facilitate your search.</p>
        <section className="u-gridPic-2 whatson-filters">
          <div>
            <h3>Filter by the nature of events:</h3>
            <FilterMenu 
              filter={natureFltr}
              updateFilter={updateNatureFilter}
              colorKeys={tagToColor}
            />
          </div>
          <div>
            <h3>Filter by organiser:</h3>
            <FilterMenu 
              filter={catFltr}
              updateFilter={updateCatFilter}
              colorKeys={catToColor}
            />
          </div>
        </section>

        <AllEvents 
          eventsArr={events}
          natureFltr={natureFltr}
          catFltr={catFltr}
        />
      </ContentBlock>
    </>
  );
};
  
export default WhatsOn;
